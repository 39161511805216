<template>
  <b-modal
    v-if="$auth.can($stringConstants('PERMISSION_CREATE_CONTRACT'))"
    id="modalAlias"
    ref="modalAlias"
    title="Додати касу, платіжний напрямок"
    @shown="paymentDirection = ''"
    hide-footer
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(onSubmit)">
        <b-form-group label-size="lg" label-class="font-weight-bold">
          <ValidationProvider
            name="платіжний напрямок"
            :rules="{ required: true, regex: /^[\w\-_]{1,255}$/ }"
            v-slot="{ errors, dirty, changed }"
          >
            <b-form-input
              type="text"
              placeholder="Номер"
              v-model="paymentDirection"
              :state="
                errors.length > 0 ? false : dirty && changed ? true : null
              "
            />
            <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
            >
              {{ error }}
            </b-form-invalid-feedback>
          </ValidationProvider>
        </b-form-group>
        <div slot="footer">
          <b-button type="submit" size="sm" variant="success">
            Додати
          </b-button>
        </div>
      </b-form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
export default {
  props: ["paymentDirections"],
  name: "PaymentDirectionModal",
  data() {
    return {
      paymentDirection: null
    };
  },
  methods: {
    onSubmit() {
      if (this.paymentDirections.indexOf(this.paymentDirection) === -1) {
        this.paymentDirections.push(this.paymentDirection);
        this.$root.$emit("bv::hide::modal", "modalAlias");
      } else {
        this.$snotify.error("Каса або платіжний напрямок вже існує");
      }
    }
  }
};
</script>
