<template>
  <div>
    <form :name="name">
      <b-form-file
        v-model="uploadedFiles"
        multiple
        ref="file-input"
        class="mb-2"
        placeholder="Вибрати файл ..."
      ></b-form-file>
      <div class="mt-2" v-if="files && files.length">
        Вибрані файли:
        <div class="list-wrapper">
          <span v-for="(file, key) in files" :key="key">
            <a
              href="javascript:void(0)"
              :download="file"
              v-on:click="downloadFile(file)"
              >{{ file }}</a
            >
            <span v-on:click="removeFile(file)" class="custom-close"></span>
          </span>
        </div>
      </div>
    </form>
    <b-modal id="modalRemove" @ok="okRemove"
      ><p class="my-4">Впевнені що хочете видалити файл?</p></b-modal
    >
  </div>
</template>

<script>
import storage from "../api/api";

export default {
  props: ["name", "id"],
  data() {
    return {
      uploadedFiles: [],
      sendData: [],
      files: [],
      fileName: null
    };
  },
  watch: {
    uploadedFiles: function(v) {
      let form = new FormData();
      v.map(item => {
        if (!this.files.find(file => file == item.name)) {
          form.append("files[]", item, item.name);
        } else {
          this.$snotify.warning(`${item.name}: файл з таким ім'ям вже існує!`);
        }
      });

      if (form.entries().next().value) {
        storage.addFiles(form, this.id).then(
          () => {
            this.$snotify.success("Файли успішно збережені");
            this.getFiles();
          },
          () => this.$snotify.error("Під час загрузки файлів виникла помилка")
        );
      }
    }
  },
  mounted() {
    this.getFiles();
  },
  methods: {
    okRemove() {
      storage.deleteFiles(this.id, this.fileName).then(
        () => {
          this.$snotify.success("Файл успішно видалений");
          this.getFiles();
        },
        () => this.$snotify.error("Під час видалення файлу виникла помилка")
      );
    },
    removeFile(file) {
      this.fileName = file;
      this.$root.$emit("bv::show::modal", "modalRemove");
    },
    getFiles() {
      storage.getFiles(this.id).then(res => {
        this.files = res;
      });
    },
    downloadFile(name) {
      storage.downloadFiles(this.id, name).then(res => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      });
    }
  }
};
</script>

<style>
.custom-file-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 115px;
}
.custom-file-label::after {
  content: "Завантажити";
}
.mt-2 {
  display: flex;
}
.list-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.list-wrapper > span {
  position: relative;
}
.list-wrapper a {
  color: #73818f;
  font-size: 12px;
}
.custom-close {
  position: absolute;
  top: 6px;
  width: 12px;
  height: 12px;
  opacity: 0.3;
  margin-left: 10px;
  cursor: pointer;
}
.custom-close:hover {
  opacity: 1;
}
.custom-close:before,
.custom-close:after {
  position: absolute;
  left: 6px;
  content: " ";
  height: 13px;
  width: 1px;
  background-color: #333;
}
.custom-close:before {
  transform: rotate(45deg);
}
.custom-close:after {
  transform: rotate(-45deg);
}
</style>
