<template>
  <b-tabs>
    <b-tab title="Договір" active>
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidation">
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <number-field v-model="form.number" />
          <issued-date-field v-model="form.issued_date" />
          <ended-at-field v-model="form.ended_at" />
          <counterpart-field v-model="form.counterpart_id" />
          <contract-type-field v-model="form.type_id" />
          <state-field v-model="form.state_id" />
          <b-form-group
            label="Директор"
            label-size="lg"
            label-class="font-weight-bold"
          >
            <ceo-type-field v-model="form.ceo_type" />
            <ceo-name-field
              v-if="form.ceo_type && ceoLegalType"
              v-model="form.ceo"
            />
            <last-name-field
              v-if="form.ceo_type && !ceoLegalType"
              v-model="form.last_name"
            />
            <first-name-field
              v-if="form.ceo_type && !ceoLegalType"
              v-model="form.first_name"
            />
            <middle-name-field
              v-if="form.ceo_type && !ceoLegalType"
              v-model="form.middle_name"
            />
          </b-form-group>
          <payment-methods-field v-model="form.payment_methods" />

          <b-form-group
            label="Внутрішня комісія (тариф, %)"
            label-cols="3"
            :horizontal="true"
          >
            <b-row>
              <b-col sm="2">
                <internal-fee-field v-model="form.internal_fee" />
              </b-col>
              <b-col sm="10">
                <internal-fee-comment-field
                  v-model="form.internal_fee_comment"
                />
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group
            label="Зовнішня комісія (тариф, %)"
            label-cols="3"
            :horizontal="true"
          >
            <b-row>
              <b-col sm="2">
                <external-fee-field v-model="form.external_fee" />
              </b-col>
              <b-col sm="10">
                <external-fee-comment-field
                  v-model="form.external_fee_comment"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <payout-period-field v-model="form.payout_period_id" />
          <auto-payout-field v-model="form.auto_payout" />
          <financial-model-field v-model="form.financial_model_id" />
          <payment-destination-code-field
            v-if="!isOutcomingContract(contract.type)"
            v-model="form.payment_destination_code_id"
          />
          <payment-destination-note-field
            v-if="
              !isOutcomingContract(contract.type) &&
              isSpecialCode(form.payment_destination_code_id)
            "
            v-model="form.payment_destination_note"
            :payment-destination-code-id="form.payment_destination_code_id"
          />
          <payment-iban
            v-if="!isOutcomingContract(contract.type)"
            v-model="form.payment_iban"
          />
          <payout-destination-code-field
            v-if="
              isOutcomingContract(contract.type) ||
              isMixedContract(contract.type)
            "
            v-model="form.payout_destination_code_id"
          />
          <payout-destination-note-field
            v-if="
              (isOutcomingContract(contract.type) ||
                isMixedContract(contract.type)) &&
              isSpecialCode(form.payout_destination_code_id)
            "
            v-model="form.payout_destination_note"
            :payout-destination-code-id="form.payout_destination_code_id"
          />
          <payout-iban
            v-if="
              isOutcomingContract(contract.type) ||
              isMixedContract(contract.type)
            "
            v-model="form.payout_iban"
          />
          <upload-documents-field
            :name="'contracttsDos'"
            :id="$route.params.id"
          />

          <b-button
            v-if="$auth.can($stringConstants('PERMISSION_CREATE_CONTRACT'))"
            type="submit"
            :disabled="submitting"
            size="sm"
            variant="primary"
          >
            <i class="fa fa-dot-circle-o"></i> Зберегти
          </b-button>
        </b-form>
      </ValidationObserver>
    </b-tab>
    <b-tab title="Псевдонім або alias">
      <b-row>
        <b-col cols="6">
          <payment-directions-form />
        </b-col>
      </b-row>
    </b-tab>
  </b-tabs>
</template>
<script>
import typeMixin from "@/entity/Contracts/mixins/type";
import NumberField from "@/entity/Contracts/components/NumberField.vue";
import IssuedDateField from "@/entity/Contracts/components/IssuedDateField.vue";
import EndedAtField from "@/entity/Contracts/components/EndedAtField.vue";
import CounterpartField from "@/entity/Contracts/components/CounterpartField.vue";
import ContractTypeField from "@/entity/Contracts/components/ContractTypeField.vue";
import StateField from "@/entity/Contracts/components/StateField.vue";
import LastNameField from "@/entity/Contracts/components/LastNameField.vue";
import FirstNameField from "@/entity/Contracts/components/FirstNameField.vue";
import MiddleNameField from "@/entity/Contracts/components/MiddleNameField.vue";
import PaymentMethodsField from "@/entity/Contracts/components/PaymentMethodsField.vue";
import InternalFeeField from "@/entity/Contracts/components/InternalFeeField.vue";
import InternalFeeCommentField from "@/entity/Contracts/components/InternalFeeCommentField.vue";
import ExternalFeeField from "@/entity/Contracts/components/ExternalFeeField.vue";
import ExternalFeeCommentField from "@/entity/Contracts/components/ExternalFeeCommentField.vue";
import PayoutPeriodField from "@/entity/Contracts/components/PayoutPeriodField.vue";
import AutoPayoutField from "@/entity/Contracts/components/AutoPayoutField.vue";
import FinancialModelField from "@/entity/Contracts/components/FinancialModelField.vue";
import PaymentDestinationCodeField from "@/entity/Contracts/components/PaymentDestinationCodeField.vue";
import PaymentDestinationNoteField from "@/entity/Contracts/components/PaymentDestinationNoteField.vue";
import PaymentIban from "@/entity/Contracts/components/PaymentIban.vue";
import PayoutDestinationCodeField from "@/entity/Contracts/components/PayoutDestinationCodeField.vue";
import PayoutDestinationNoteField from "@/entity/Contracts/components/PayoutDestinationNoteField.vue";
import PayoutIban from "@/entity/Contracts/components/PayoutIban.vue";
import UploadDocumentsField from "@/entity/Contracts/components/UploadDocumentsField.vue";
import PaymentDirectionsForm from "@/entity/Contracts/forms/cards/parts/PaymentDirectionsForm.vue";
import CeoTypeField from "@/entity/Contracts/components/CeoTypeField.vue";
import CeoNameField from "@/entity/Contracts/components/CeoNameField.vue";
import { mapGetters } from "vuex";

export default {
  props: ["contract", "submitting"],
  name: "DefaultContractForm",
  mixins: [typeMixin],
  components: {
    CeoNameField,
    CeoTypeField,
    NumberField,
    IssuedDateField,
    EndedAtField,
    CounterpartField,
    ContractTypeField,
    StateField,
    LastNameField,
    FirstNameField,
    MiddleNameField,
    PaymentMethodsField,
    InternalFeeField,
    InternalFeeCommentField,
    ExternalFeeField,
    ExternalFeeCommentField,
    PayoutPeriodField,
    AutoPayoutField,
    FinancialModelField,
    PaymentDestinationCodeField,
    PaymentDestinationNoteField,
    PaymentIban,
    PayoutDestinationCodeField,
    PayoutDestinationNoteField,
    PayoutIban,
    UploadDocumentsField,
    PaymentDirectionsForm,
  },
  data() {
    return {
      form: {
        id: null,
        number: "",
        issued_date: "",
        ended_at: "",
        counterpart_id: null,
        type_id: null,
        state_id: null,
        last_name: "",
        first_name: "",
        middle_name: "",
        payment_methods: [],
        internal_fee: "",
        internal_fee_comment: "",
        external_fee: "",
        external_fee_comment: "",
        payout_period_id: null,
        auto_payout: false,
        financial_model_id: null,
        payment_destination_code_id: null,
        payment_destination_note: "",
        payment_iban: "",
        payout_destination_code_id: null,
        payout_destination_note: "",
        payout_iban: "",
        ceo_type: null,
        ceo: "",
      },
    };
  },
  watch: {
    contract: {
      deep: true,
      handler(value) {
        this.form.id = value.id;
        this.form.number = value.number || "";
        this.form.issued_date = value.issued_date || "";
        this.form.ended_at = value.ended_at || "";
        this.form.counterpart_id = value.counterpart?.id || null;
        this.form.type_id = value.type?.id || null;
        this.form.state_id = value.state?.id || null;
        this.form.last_name = value.last_name || "";
        this.form.first_name = value.first_name || "";
        this.form.middle_name = value.middle_name || "";
        this.form.payment_methods = value.payment_methods.length
          ? value.payment_methods.map((item) => item.id)
          : [];
        this.form.internal_fee = value.internal_fee || "";
        this.form.internal_fee_comment = value.internal_fee_comment || "";
        this.form.external_fee = value.external_fee || "";
        this.form.external_fee_comment = value.external_fee_comment || "";
        this.form.payout_period_id = value.payout_period?.id || null;
        this.form.auto_payout = value.auto_payout;
        this.form.financial_model_id = value.financial_model?.id || null;
        this.form.payment_destination_code_id =
          value.payment_destination_code?.id || null;
        this.form.payment_destination_note = value.payment_destination_note;
        this.form.payment_iban = value.payment_iban || "";
        this.form.payout_destination_code_id =
          value.payout_destination_code?.id || null;
        this.form.payout_destination_note = value.payout_destination_note || "";
        this.form.payout_iban = value.payout_iban || "";
        this.form.ceo_type = value.ceo_type?.value || null;
        this.form.ceo = value.ceo || "";

        requestAnimationFrame(() => {
          this.$refs.formValidation.reset();
        });
      },
    },
  },
  computed: {
    ...mapGetters({
      ceoTypes: "dictionary/allContractCeoTypes",
    }),
    getChangedFields() {
      const keys = Object.keys(this.$refs.formValidation.fields).reduce(
        (accumulator, key) => {
          if (
            this.$refs.formValidation.fields[key].changed === true &&
            key in this.form
          ) {
            accumulator.push(key);
          }

          return accumulator;
        },
        []
      );

      return Object.keys(this.form).reduce(
        (accumulator, key) => {
          if (keys.includes(key)) {
            accumulator[key] = this.form[key];
          }

          return accumulator;
        },
        { id: this.form.id }
      );
    },
    ceoLegalType() {
      if (this.form.ceo_type) {
        const foundItem = this.ceoTypes.find(
          (item) => item.value === this.form.ceo_type
        );

        return (
          foundItem && foundItem.value === this.$stringConstants("LEGAL_ENTITY")
        );
      }
      return false;
    },
  },
  methods: {
    onSubmit() {
      this.$emit("send-form", this.getChangedFields);
    },
  },
};
</script>
