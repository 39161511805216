import {
  //CONTRACT_INCOMING_TYPE_VALUE,
  CONTRACT_OUTCOMING_TYPE_VALUE,
  CONTRACT_MIXED_TYPE_VALUE,
  SPECIAL_DESTINATION_CODES
  //CONTRACT_PAYSYSTEM_TYPE_VALUE
} from "@/constants";

export default {
  methods: {
    isOutcomingContract(type) {
      return this.isTypeValue(type, CONTRACT_OUTCOMING_TYPE_VALUE);
    },
    isMixedContract(type) {
      return this.isTypeValue(type, CONTRACT_MIXED_TYPE_VALUE);
    },
    isTypeValue(type, typeValue) {
      if (!type) return false;
      const types = this.$store.getters["dictionary/allContractTypes"];
      const foundType = types.find(item => item.id === type.id);
      if (foundType === -1) return false;
      return foundType.value === typeValue;
    },
    isSpecialCode(code) {
      if (!code) return false;
      let codeId = typeof code === "object" ? code.id : code;
      const codes = this.$store.getters[
        "dictionary/allOperationDestinationCode"
      ];
      const foundType = codes.find(item => item.id === codeId);
      if (foundType === -1) return false;
      return this.$stringConstants("SPECIAL_DESTINATION_CODES").includes(
        foundType.value
      );
    }
  }
};
