<template>
  <b-form-group label="Завантажити документи" label-cols="3" :horizontal="true">
    <uploader :name="'contracttsDos'" :id="id"></uploader>
  </b-form-group>
</template>
<script>
import Uploader from "@/components/Uploader.vue";

export default {
  name: "UploadDocumentsField",
  props: ["name", "id"],
  components: {
    Uploader
  }
};
</script>
