<template>
  <ValidationProvider
    v-slot="{ errors, dirty }"
    name="рахунок виплати"
    :rules="{ required: true, regex: /^[A-Z]{2}[\d]{27}$/ }"
    vid="payout_iban"
  >
    <b-form-group label="Рахунок виплати" label-cols="3" :horizontal="true">
      <b-form-input
        type="text"
        placeholder="Рахунок виплати в Iban форматі"
        maxlength="29"
        v-model="valueFiled"
        :state="errors.length > 0 ? false : dirty ? true : null"
      />
      <b-form-invalid-feedback
        :state="errors.length === 0"
        v-for="(error, index) in errors"
        v-bind:key="index"
      >
        {{ error }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
export default {
  name: "PayoutIban",
  props: ["value"],
  computed: {
    valueFiled: {
      get: function () {
        return this.value;
      },
      set: function (v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
