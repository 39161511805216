<template>
  <ValidationProvider
    name="Код призначення виплати"
    rules="required"
    v-slot="{ errors, dirty, changed }"
    vid="payout_destination_code_id"
  >
    <b-form-group
      label="Код призначення виплати"
      label-cols="3"
      :horizontal="true"
    >
      <b-form-select
        v-model="valueFiled"
        :disabled="true"
        :state="errors.length > 0 ? false : dirty && changed ? true : null"
      >
        <option
          v-for="option in operationDestinationCode"
          :key="option.id"
          :value="option.id"
        >
          {{ `(${option.value}) ${option.name}` }}
        </option>
      </b-form-select>
      <b-form-invalid-feedback
        :state="errors.length === 0"
        v-for="(error, index) in errors"
        v-bind:key="index"
      >
        {{ error }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "PayoutDestinationCodeField",
  props: ["value"],
  computed: {
    ...mapGetters({
      operationDestinationCode: "dictionary/allOperationDestinationCode"
    }),
    valueFiled: {
      get: function() {
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    }
  }
};
</script>
