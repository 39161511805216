<template>
  <ValidationProvider
    name="Дозволити автоматичне формування виплат"
    v-slot="{ errors, dirty, changed }"
    rules="required"
    vid="auto_payout"
  >
    <b-form-group
      label="Дозволити автоматичне формування виплат"
      label-cols="3"
      :horizontal="true"
    >
      <b-form-radio-group
        v-model="valueFiled"
        :state="errors.length > 0 ? false : dirty && changed ? true : null"
        :options="[
          { text: 'Так', value: true },
          { text: 'Нi', value: false }
        ]"
      />
      <b-form-invalid-feedback
        :state="errors.length === 0"
        v-for="(error, index) in errors"
        v-bind:key="index"
      >
        {{ error }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "AutoPayoutField",
  props: ["value"],
  computed: {
    ...mapGetters({
      paymentMethods: "dictionary/allContractPaymentMethods"
    }),
    valueFiled: {
      get: function() {
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    }
  }
};
</script>
