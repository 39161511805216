<template>
  <ValidationProvider
    name="Внутрішня комісія коментар"
    rules="max:255"
    v-slot="{ errors, dirty, changed }"
    vid="internal_fee_comment"
  >
    <b-form-input
      type="text"
      v-model="valueFiled"
      :state="errors.length > 0 ? false : dirty && changed ? true : null"
      placeholder="Коментар"
    />
    <b-form-invalid-feedback
      :state="errors.length === 0"
      v-for="(error, index) in errors"
      v-bind:key="index"
    >
      {{ error }}
    </b-form-invalid-feedback>
  </ValidationProvider>
</template>
<script>
export default {
  name: "InternalFeeCommentField",
  props: ["value"],
  computed: {
    valueFiled: {
      get: function() {
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    }
  }
};
</script>
