<template>
  <ValidationProvider
    name="Статус"
    rules="required"
    v-slot="{ errors, dirty, changed }"
    vid="state_id"
  >
    <b-form-group label="Статус" label-cols="3" :horizontal="true">
      <b-form-select
        v-model="valueFiled"
        :options="states"
        text-field="name"
        value-field="id"
        :state="errors.length > 0 ? false : dirty && changed ? true : null"
      />
      <b-form-invalid-feedback
        :state="errors.length === 0"
        v-for="(error, index) in errors"
        v-bind:key="index"
      >
        {{ error }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "StateField",
  props: ["value"],
  computed: {
    ...mapGetters({
      states: "dictionary/allContractStatuses"
    }),
    valueFiled: {
      get: function() {
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    }
  }
};
</script>
