<template>
  <ValidationProvider
    name="Дата укладання договору"
    rules="required"
    v-slot="{ errors, dirty, changed }"
    vid="issued_date"
  >
    <b-form-group
      label="Дата укладання договору"
      label-cols="3"
      :horizontal="true"
    >
      <b-form-input
        type="date"
        v-model="valueFiled"
        :state="errors.length > 0 ? false : dirty && changed ? true : null"
      />
      <b-form-invalid-feedback
        :state="errors.length === 0"
        v-for="(error, index) in errors"
        v-bind:key="index"
      >
        {{ error }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
export default {
  name: "IssuedDateField",
  props: ["value"],
  computed: {
    valueFiled: {
      get: function() {
        return this.value;
      },
      set: function(v) {
        this.$emit("input", v);
      }
    }
  }
};
</script>
