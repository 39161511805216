<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <div slot="header">
          <strong>Договір {{ contract.number }}</strong>
        </div>
        <component
          :is="componentForm"
          :contract="contract"
          :submitting="submitting"
          v-on:send-form="submitForm"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultContractForm from "@/entity/Contracts/forms/cards/DefaultContractForm.vue";
import Code3810ContractForm from "@/entity/Contracts/forms/cards/Code3810ContractForm.vue";

export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.$auth.can("view contract")) next();
      else next({ name: "Page404" });
    });
  },
  name: "ContractItem",
  components: {
    DefaultContractForm,
    Code3810ContractForm
  },
  data() {
    return {
      submitting: false
    };
  },
  beforeDestroy() {
    this.$store.dispatch("contract/flush");
  },
  created() {
    this.$store.dispatch("contract/pull", this.$route.params.id);
  },
  computed: {
    componentForm: {
      get() {
        const code = Object.keys(this.contract).length
          ? this.contract.financial_model.code
          : null;

        const componentToLoad = code
          ? `Code${code.value}ContractForm`
          : "DefaultContractForm";

        return this.isComponentRegistered(componentToLoad)
          ? componentToLoad
          : "DefaultContractForm";
      }
    },
    ...mapGetters({
      contract: "contract/get"
    })
  },
  methods: {
    isComponentRegistered(componentName) {
      return !!this.$options.components[componentName];
    },
    submitForm(fields) {
      this.submitting = true;
      this.$store
        .dispatch("contract/update", fields)
        .then(() => (this.submitting = false))
        .catch(() => (this.submitting = false));
    }
  }
};
</script>
