<template>
  <div>
    <b-form>
      <b-alert :show="invalid" variant="danger" v-html="errorMessage">
      </b-alert>
      <b-form-group label-size="lg" label-class="font-weight-bold">
        <b-table
          :fields="fields"
          :hover="true"
          :outlined="true"
          :fixed="true"
          :small="true"
          :items="form.paymentDirections"
        >
          <template v-slot:cell(id)="data">
            {{ data.item }}
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
              variant="link text-danger"
              size="sm"
              @click="form.paymentDirections.splice(data.index, 1)"
              ><i class="fa fa-close"></i>
            </b-button>
          </template>
        </b-table>
        <b-button
          variant="link text-success"
          size="sm"
          v-b-modal.modalAlias
          class="mb-2"
          ><i class="fa fa-plus"></i
        ></b-button>
      </b-form-group>
      <b-button
        v-if="$auth.can($stringConstants('PERMISSION_CREATE_CONTRACT'))"
        @click.prevent="onSubmitPaymentDirections"
        size="sm"
        variant="primary"
        ><i class="fa fa-dot-circle-o"></i> Зберегти
      </b-button>
    </b-form>
    <payment-direction-modal :paymentDirections="form.paymentDirections" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import PaymentDirectionModal from "@/entity/Contracts/components/PaymentDirectionModal.vue";

export default {
  name: "PaymentDirectionsForm",
  components: {
    PaymentDirectionModal
  },
  data() {
    const fields = [{ key: "id", label: "Номер" }];
    if (this.$auth.can(this.$stringConstants("PERMISSION_CREATE_CONTRACT")))
      fields.push({ key: "actions", label: "Дії" });
    return {
      invalid: false,
      fields: fields,
      errorMessage: "",
      form: {
        paymentDirections: null
      }
    };
  },
  computed: {
    ...mapGetters({
      paymentDirections: "contract/getPaymentDirections"
    })
  },
  watch: {
    paymentDirections: {
      handler(newVal) {
        this.form.paymentDirections = newVal.map(item => item.name);
      }
    }
  },
  methods: {
    onSubmitPaymentDirections() {
      this.invalid = false;
      this.$store
        .dispatch("contract/updatePaymentDirections", {
          id: this.$route.params.id,
          form: this.form.paymentDirections
        })
        .then(data => {
          if (data && data.description) {
            this.invalid = true;
            this.errorMessage = "";
            Object.values(data.description).forEach(function(value) {
              let contractId = value[0].substr(value[0].lastIndexOf(":") + 1);
              if (contractId.length !== 36) {
                this.errorMessage += value[0] + "\n";
              } else {
                let message = value[0].substr(0, value[0].length - 36);
                let link =
                  '<a target="_blank" href="/entity/contract/' +
                  contractId +
                  '">' +
                  contractId +
                  "</a>";
                this.errorMessage += message + " " + link + "\n";
              }
            }, this);
          }
        });
    }
  }
};
</script>
