<template>
  <ValidationProvider
    name="Тип"
    v-slot="{ errors, dirty, changed }"
    vid="ceo_type"
  >
    <b-form-group label="Тип" label-cols="3" :horizontal="true">
      <b-form-select
        v-model="valueFiled"
        :options="types"
        text-field="name"
        value-field="value"
        :disabled="valueFiled && !(dirty && changed)"
        :state="errors.length > 0 ? false : dirty && changed ? true : null"
      />
      <b-form-invalid-feedback
        :state="errors.length === 0"
        v-for="(error, index) in errors"
        v-bind:key="index"
      >
        {{ error }}
      </b-form-invalid-feedback>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "CeoTypeField",
  props: ["value"],
  computed: {
    ...mapGetters({
      types: "dictionary/allContractCeoTypes",
    }),
    valueFiled: {
      get: function () {
        return this.value;
      },
      set: function (v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>
